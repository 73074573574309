"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Routes;
(function (Routes) {
    Routes["index"] = "/";
    Routes["job"] = "/job";
    Routes["about"] = "/about";
    Routes["contacts"] = "/contacts";
    Routes["projects"] = "/projects";
    Routes["project"] = "/projects/:slug";
    Routes["partners"] = "/partners";
    Routes["partner"] = "/partners/:partnerName";
    Routes["catalog"] = "/catalog";
    Routes["catalogCategory"] = "/catalog/:categoryName";
    Routes["products"] = "/products";
    Routes["product"] = "/products/:slug";
})(Routes = exports.Routes || (exports.Routes = {}));
