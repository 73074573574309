"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var mobx_1 = require("mobx");
var Store = /** @class */ (function () {
    function Store(initialData) {
        if (initialData) {
            this.setData(initialData);
        }
    }
    Store.prototype.setData = function (data) {
        this.data = data;
    };
    tslib_1.__decorate([
        mobx_1.observable
    ], Store.prototype, "data", void 0);
    tslib_1.__decorate([
        mobx_1.action
    ], Store.prototype, "setData", null);
    return Store;
}());
exports.Store = Store;
