"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.metrikaGoals = {
    formSubmit: 'form-submit',
    formSuccess: 'form-success',
    formError: 'form-error',
    emailClick: 'email-click',
    phoneClick: 'phone-click',
};
function metrika(goal) {
    var counter = window.yaCounter45472281;
    if (counter) {
        counter.reachGoal(goal);
    }
    else {
        console.log("NO COUNTER!");
    }
    console.log("Sending metrika: id " + goal);
}
exports.metrika = metrika;
function sentryReport(error, extra) {
    if (extra === void 0) { extra = null; }
    var raven = window.Raven;
    if (raven && raven.captureException) {
        raven.captureException(error, { extra: extra });
    }
}
exports.sentryReport = sentryReport;
function initSentry() {
    var raven = window.Raven;
    if (raven && raven.config) {
        raven.config('https://e6d3b69b0aff45b691803e365d04155c@sentry.io/197471').install();
    }
}
exports.initSentry = initSentry;
function getInitialState() {
    return window.__INITIAL_STATE__;
}
exports.getInitialState = getInitialState;
exports.canUseDOM = Boolean(typeof window !== 'undefined' && window.document && window.document.createElement);
function shortenString(string, maxLength, separator) {
    if (separator === void 0) { separator = ' '; }
    return string.length <= maxLength
        ? string
        : string.substr(0, string.lastIndexOf(separator, maxLength - 1)) + '…';
}
exports.shortenString = shortenString;
function stripHtml(html) {
    return html.replace(/<[^>]*>?/gm, '');
}
exports.stripHtml = stripHtml;
